@import '../../style/style.sass'

.skill-card
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 130px
    height: 130px
    color: $gray-color
    filter: grayscale(90%)
    &__icon
        width: 90px
        height: 90px
        object-fit: contain
    &__title
        font-size: 20px
    &:hover
        color: whitesmoke
        filter: grayscale(0%)
        transform: scale(1.3)
        transition: all .8s