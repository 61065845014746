@import '../../style/style.sass'
    
.about
    margin-top: 100px
    margin-bottom: 40px
    width: 80%
    &__wrapper
        display: grid
        grid-template-columns: 47% 48%
        column-gap: 5%
    &__img
        width: 100%
        height: 600px
        object-fit: cover
        filter: grayscale(80%)
    &__title
        font-size: 18px
        font-weight: bold
        text-align: center
        margin-bottom: 15px
    &__description
        font-size: 16px
        text-align: justify
        text-indent: 20px
    &__tabs
        margin-top: 35px
        margin-bottom: 25px
    &__experience
        margin-top: 20px
        margin-bottom: 15px
        font-weight: bold
        text-align: center
        &_list
            color: $gray-color
            &-item
                margin-bottom: 15px

@media (max-width: 991px)
    .about
        width: 100%

@media (max-width: 756px)
    .about
        &__wrapper
            grid-template-columns: unset
            grid-template-rows: 350px auto
            row-gap: 15px
        &__img
            height: 350px

@media (max-width: 425px)
    .about
        margin-top: 70px
        margin-bottom: 30px
        &__wrapper
            grid-template-rows: 300px auto
        &__img
            height: 300px
        &__title
            font-size: 16px
        &__description, &__experience_list
            font-size: 14px