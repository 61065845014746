@import '../../style/style.sass'
    
.contacts
    margin-top: 100px
    margin-bottom: 40px
    width: 80%
    &__wrapper
        display: grid
        grid-template-columns: 47% 49%
        column-gap: 4%
    &__img
        width: 100%
        height: 600px
        object-fit: cover
        filter: grayscale(90%)
    &__text
        color: $gray-color
    &__title
        font-size: 32px
        font-weight: bold
    &__subtitle
        font-size: 24px
        margin-bottom: 20px
        &_sub
            font-size: 18px
    &__phone, &__email
        font-size: 18px
        margin-bottom: 10px
        a
            color: $gray-color
            &:hover
                color: whitesmoke
    &__telegram
        color: $gray-color
        font-size: 28px
        &:hover
            color: whitesmoke
    &__triggers
        display: flex
        justify-content: space-between
        align-items: end
        margin-top: 30px
        .btn
            font-size: 14px
        input + span
            font-size: 12px
            margin-left: 10px

@media (max-width: 991px)
    .contacts
        width: 100%
        &__title
            font-size: 28px
        &__subtitle
            font-size: 20px

@media (max-width: 767px)
    .contacts
        &__wrapper
            display: block
        &__img
            display: none

@media (max-width: 575px)
    .contacts
        margin-top: 70px
        margin-bottom: 20px
        padding-left: 15px
        padding-right: 15px
        &__title
            font-size: 24px
        &__subtitle
            font-size: 18px
            &_sub
                font-size: 16px
        &__phone, &__email
            font-size: 16px
        &__triggers
            display: block
            .btn
                width: 100%
                margin-bottom: 10px
            input + span
                font-size: 11px