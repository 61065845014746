.main
    position: relative
    height: 100vh
    background: url('../../assets/main/main_bg.jpg') center / cover no-repeat
    &__typewriter
        position: absolute
        top: 300px
        left: 60px
        font-size: 36px
        font-weight: bold
        text-transform: uppercase
        overflow: hidden
        border-right: .25em solid #b71c1c
        white-space: nowrap
        letter-spacing: .2em
        animation: typing 4s steps(40, end), blink-caret .75s step-end infinite
    &__text
        position: absolute
        top: 400px
        left: 100px
        width: 400px
        text-align: justify

@keyframes typing 
    from 
        width: 0
    to 
        width: 535px

@keyframes blink-caret 
    from, to 
        border-color: transparent
    50% 
        border-color: #b71c1c


@media (max-width: 1230px)
    .main
        &__typewriter
            left: 40px
            font-size: 32px
    @keyframes typing 
        from 
            width: 0
        to 
            width: 485px

@media (max-width: 1024px)
    .main
        &__typewriter
            top: 200px
            left: 30px
            font-size: 30px
        &__text
            top: 300px
            left: 70px
            width: 350px
            font-size: 15px
    @keyframes typing 
        from 
            width: 0
        to 
            width: 453px

@media (max-width: 890px)
    .main
        &__typewriter
            top: 100px
            left: 30px
            font-size: 28px
        &__text
            top: 200px
            left: 50px
            width: 300px
            font-size: 14px

@media (max-width: 425px)
    .main
        &__typewriter
            left: 20px
            font-size: 21px
            letter-spacing: .1em
            width: 280px
        &__text
            width: 60%
            top: 250px
            left: 20px
    @keyframes typing 
        from 
            width: 0
        to 
            width: 275px