.modal-content
    background-color: #222222
    border-color: #444444

.nav-link
    color: rgba(198,201,216,.75)!important
    font-weight: bold
    &.active
        background-color: #222222!important
        color: #b71c1c!important
        border-color: rgba(198,201,216,.75)!important