@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css')
@import 'bootstrap-icons/font/bootstrap-icons.css'
@import './custom.sass'

$gray-color: rgba(198,201,216,.75)

*
    box-sizing: border-box
    margin: 0 
    padding: 0 

// #root
//     padding-bottom: 30px

body
    overflow-y: scroll
    background-color: #222222
    color: whitesmoke

a
    color: whitesmoke
    text-decoration: none
    &:hover
        color: whitesmoke

.list-item__icon
        margin-right: 30px
        cursor: pointer
        &:first-child:hover
            color: #0277bd 
        &:last-child:hover
            color: #e53935 