.skills
    margin-top: 130px
    margin-bottom: 40px
    width: 70%
    display: grid
    grid-template-columns: repeat(auto-fit, 140px)
    gap: 30px
    justify-content: center

@media (max-width: 756px)
    .skills
        width: 80%

@media (max-width: 425px)
    .skills
        width: 100%