.project-item
    display: grid
    grid-template-columns: 27% 70%
    column-gap: 3%
    margin-top: 25px
    padding: 13px
    line-height: 24px
    background-color: #333333
    border-color: #555555
    &__img
        width: 200px
        height: 200px
        object-fit: cover
    &__title
        font-size: 18px
        font-weight: bold
        text-transform: uppercase
    &__links
        font-size: 14px
        font-style: italic
        a
            margin-right: 40px
    &__stack, &__description
        margin-top: 10px
        margin-bottom: 10px
        font-size: 16px
        text-align: justify

@media (max-width: 1199px)
    .project-item
        grid-template-columns: 32% 65%

@media (max-width: 991px)
    .project-item
        grid-template-columns: 43% 54%

@media (max-width: 575px)
    .project-item
        grid-template-columns: unset
        grid-template-rows: 200px minmax(150px auto)
        row-gap: 10px
        &__img
            width: 100%
            height: 200px
        &__title
            font-size: 16px
        &__stack, &__description
            font-size: 14px